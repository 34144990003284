import React, {useContext} from 'react';
import {graphql, Link} from 'gatsby';
import Layout from '@components/layout';
import SEO from '@components/seo';
import EdmusContext from '@components/application.provider';
import useLabels from '@hooks/useLabels';
import Helmet from 'react-helmet';

export const query = graphql`
  {
    prismic {
      allNewsletters {
        edges {
          node {
            title
            image
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

const Newsletter = (props: any) => {
  const {language} = useContext(EdmusContext);
  const {allNewsletters} = props.data.prismic;
  const {labels} = useLabels();
  const doc: any = allNewsletters.edges.find(
    (docs: any) => docs.node._meta.lang.slice(0, 2) === language
  );
  const mailTo = () => {
    if (doc.node.email) window.location.assign('mailto:' + doc.node.email);
  };

  if (!doc) return null;

  return (
    <Layout>
      <div className='edmus-club'>
        <SEO title={doc.node.title} />
        <img
          className='img-bar lazyloading'
          src={doc.node.image.url}
          alt={doc.node.image.alt}
        />
        <div className='container sub-container contact'>
          <div className='columns space-top-20'>
            <div className='col-12 page-title text-center'>
              {doc.node.title}
            </div>
            <div className='col-12 text text-center'>{labels.EDMUS_CLUB}</div>
            <div className='col-12 text-center'>{labels.EDMUS_CLUB_LINE_1}</div>
            <div className='column col-12'>
              <form
                method='post'
                action='https://systeme.io/embedded/8511656/subscription'
              >
                <div className='form-group'>Prénom</div>
                <div className='form-group'>
                  <input
                    type='text'
                    name='surname'
                    required
                    className='form-input'
                  />
                </div>
                <div className='form-group'>Nom</div>
                <div className='form-group'>
                  <input
                    type='text'
                    name='first_name'
                    required
                    className='form-input'
                  />
                </div>
                <div className='form-group'>Email</div>
                <div className='form-group'>
                  <input
                    type='email'
                    name='email'
                    required
                    className='form-input'
                  />
                </div>
                <div className='form-group'>Pays</div>
                <div className='form-group'>
                  <select name='country' required className='form-select'>
                    <option value='FR'>France</option>
                    <option value='BE'>Belgique</option>
                    <option value='CA'>Canada</option>
                    <option value='CH'>Suisse</option>
                    <option value='AF'>Afghanistan</option>
                    <option value='ZA'>Afrique du Sud</option>
                    <option value='AL'>Albanie</option>
                    <option value='DZ'>Algérie</option>
                    <option value='DE'>Allemagne</option>
                    <option value='AD'>Andorre</option>
                    <option value='AO'>Angola</option>
                    <option value='AI'>Anguilla</option>
                    <option value='AQ'>Antarctique</option>
                    <option value='AG'>Antigua-et-Barbuda</option>
                    <option value='SA'>Arabie saoudite</option>
                    <option value='AR'>Argentine</option>
                    <option value='AM'>Arménie</option>
                    <option value='AW'>Aruba</option>
                    <option value='AU'>Australie</option>
                    <option value='AT'>Autriche</option>
                    <option value='AZ'>Azerbaïdjan</option>
                    <option value='BS'>Bahamas</option>
                    <option value='BH'>Bahreïn</option>
                    <option value='BD'>Bangladesh</option>
                    <option value='BB'>Barbade</option>
                    <option value='BZ'>Belize</option>
                    <option value='BM'>Bermudes</option>
                    <option value='BT'>Bhoutan</option>
                    <option value='BY'>Biélorussie</option>
                    <option value='BO'>Bolivie</option>
                    <option value='BA'>Bosnie-Herzégovine</option>
                    <option value='BW'>Botswana</option>
                    <option value='BN'>Brunei</option>
                    <option value='BR'>Brésil</option>
                    <option value='BG'>Bulgarie</option>
                    <option value='BF'>Burkina Faso</option>
                    <option value='BI'>Burundi</option>
                    <option value='BJ'>Bénin</option>
                    <option value='KH'>Cambodge</option>
                    <option value='CM'>Cameroun</option>
                    <option value='CV'>Cap-Vert</option>
                    <option value='CL'>Chili</option>
                    <option value='CN'>Chine</option>
                    <option value='CY'>Chypre</option>
                    <option value='CO'>Colombie</option>
                    <option value='KM'>Comores</option>
                    <option value='CG'>Congo-Brazzaville</option>
                    <option value='CD'>Congo-Kinshasa</option>
                    <option value='KP'>Corée du Nord</option>
                    <option value='KR'>Corée du Sud</option>
                    <option value='CR'>Costa Rica</option>
                    <option value='HR'>Croatie</option>
                    <option value='CU'>Cuba</option>
                    <option value='CW'>Curaçao</option>
                    <option value='CI'>Côte d’Ivoire</option>
                    <option value='DK'>Danemark</option>
                    <option value='DJ'>Djibouti</option>
                    <option value='DM'>Dominique</option>
                    <option value='ES'>Espagne</option>
                    <option value='EE'>Estonie</option>
                    <option value='SZ'>Eswatini</option>
                    <option value='FJ'>Fidji</option>
                    <option value='FI'>Finlande</option>
                    <option value='GA'>Gabon</option>
                    <option value='GM'>Gambie</option>
                    <option value='GH'>Ghana</option>
                    <option value='GI'>Gibraltar</option>
                    <option value='GD'>Grenade</option>
                    <option value='GL'>Groenland</option>
                    <option value='GR'>Grèce</option>
                    <option value='GP'>Guadeloupe</option>
                    <option value='GU'>Guam</option>
                    <option value='GT'>Guatemala</option>
                    <option value='GG'>Guernesey</option>
                    <option value='GN'>Guinée</option>
                    <option value='GQ'>Guinée équatoriale</option>
                    <option value='GW'>Guinée-Bissau</option>
                    <option value='GY'>Guyana</option>
                    <option value='GF'>Guyane française</option>
                    <option value='GE'>Géorgie</option>
                    <option value='GS'>
                      Géorgie du Sud-et-les Îles Sandwich du Sud
                    </option>
                    <option value='HT'>Haïti</option>
                    <option value='HN'>Honduras</option>
                    <option value='HU'>Hongrie</option>
                    <option value='IN'>Inde</option>
                    <option value='ID'>Indonésie</option>
                    <option value='IQ'>Irak</option>
                    <option value='IR'>Iran</option>
                    <option value='IE'>Irlande</option>
                    <option value='IS'>Islande</option>
                    <option value='IL'>Israël</option>
                    <option value='IT'>Italie</option>
                    <option value='JM'>Jamaïque</option>
                    <option value='JP'>Japon</option>
                    <option value='JE'>Jersey</option>
                    <option value='JO'>Jordanie</option>
                    <option value='KZ'>Kazakhstan</option>
                    <option value='KE'>Kenya</option>
                    <option value='KG'>Kirghizstan</option>
                    <option value='KI'>Kiribati</option>
                    <option value='XK'>Kosovo</option>
                    <option value='KW'>Koweït</option>
                    <option value='RE'>La Réunion</option>
                    <option value='LA'>Laos</option>
                    <option value='LS'>Lesotho</option>
                    <option value='LV'>Lettonie</option>
                    <option value='LB'>Liban</option>
                    <option value='LR'>Liberia</option>
                    <option value='LY'>Libye</option>
                    <option value='LI'>Liechtenstein</option>
                    <option value='LT'>Lituanie</option>
                    <option value='LU'>Luxembourg</option>
                    <option value='MK'>Macédoine du Nord</option>
                    <option value='MG'>Madagascar</option>
                    <option value='MY'>Malaisie</option>
                    <option value='MW'>Malawi</option>
                    <option value='MV'>Maldives</option>
                    <option value='ML'>Mali</option>
                    <option value='MT'>Malte</option>
                    <option value='MA'>Maroc</option>
                    <option value='MQ'>Martinique</option>
                    <option value='MU'>Maurice</option>
                    <option value='MR'>Mauritanie</option>
                    <option value='YT'>Mayotte</option>
                    <option value='MX'>Mexique</option>
                    <option value='FM'>Micronésie</option>
                    <option value='MD'>Moldavie</option>
                    <option value='MC'>Monaco</option>
                    <option value='MN'>Mongolie</option>
                    <option value='MS'>Montserrat</option>
                    <option value='ME'>Monténégro</option>
                    <option value='MZ'>Mozambique</option>
                    <option value='MM'>Myanmar (Birmanie)</option>
                    <option value='NA'>Namibie</option>
                    <option value='NR'>Nauru</option>
                    <option value='NI'>Nicaragua</option>
                    <option value='NE'>Niger</option>
                    <option value='NG'>Nigeria</option>
                    <option value='NU'>Niue</option>
                    <option value='NO'>Norvège</option>
                    <option value='NC'>Nouvelle-Calédonie</option>
                    <option value='NZ'>Nouvelle-Zélande</option>
                    <option value='NP'>Népal</option>
                    <option value='OM'>Oman</option>
                    <option value='UG'>Ouganda</option>
                    <option value='UZ'>Ouzbékistan</option>
                    <option value='PK'>Pakistan</option>
                    <option value='PW'>Palaos</option>
                    <option value='PA'>Panama</option>
                    <option value='PG'>Papouasie-Nouvelle-Guinée</option>
                    <option value='PY'>Paraguay</option>
                    <option value='NL'>Pays-Bas</option>
                    <option value='BQ'>Pays-Bas caribéens</option>
                    <option value='PH'>Philippines</option>
                    <option value='PL'>Pologne</option>
                    <option value='PF'>Polynésie française</option>
                    <option value='PR'>Porto Rico</option>
                    <option value='PT'>Portugal</option>
                    <option value='PE'>Pérou</option>
                    <option value='QA'>Qatar</option>
                    <option value='HK'>R.A.S. chinoise de Hong Kong</option>
                    <option value='MO'>R.A.S. chinoise de Macao</option>
                    <option value='RO'>Roumanie</option>
                    <option value='GB'>Royaume-Uni</option>
                    <option value='RU'>Russie</option>
                    <option value='RW'>Rwanda</option>
                    <option value='CF'>République centrafricaine</option>
                    <option value='DO'>République dominicaine</option>
                    <option value='EH'>Sahara occidental</option>
                    <option value='BL'>Saint-Barthélemy</option>
                    <option value='KN'>Saint-Christophe-et-Niévès</option>
                    <option value='SM'>Saint-Marin</option>
                    <option value='MF'>Saint-Martin</option>
                    <option value='SX'>
                      Saint-Martin (partie néerlandaise)
                    </option>
                    <option value='PM'>Saint-Pierre-et-Miquelon</option>
                    <option value='VC'>Saint-Vincent-et-les Grenadines</option>
                    <option value='SH'>Sainte-Hélène</option>
                    <option value='LC'>Sainte-Lucie</option>
                    <option value='SV'>Salvador</option>
                    <option value='WS'>Samoa</option>
                    <option value='AS'>Samoa américaines</option>
                    <option value='ST'>Sao Tomé-et-Principe</option>
                    <option value='RS'>Serbie</option>
                    <option value='SC'>Seychelles</option>
                    <option value='SL'>Sierra Leone</option>
                    <option value='SG'>Singapour</option>
                    <option value='SK'>Slovaquie</option>
                    <option value='SI'>Slovénie</option>
                    <option value='SO'>Somalie</option>
                    <option value='SD'>Soudan</option>
                    <option value='SS'>Soudan du Sud</option>
                    <option value='LK'>Sri Lanka</option>
                    <option value='SR'>Suriname</option>
                    <option value='SE'>Suède</option>
                    <option value='SJ'>Svalbard et Jan Mayen</option>
                    <option value='SY'>Syrie</option>
                    <option value='SN'>Sénégal</option>
                    <option value='TJ'>Tadjikistan</option>
                    <option value='TZ'>Tanzanie</option>
                    <option value='TW'>Taïwan</option>
                    <option value='TD'>Tchad</option>
                    <option value='CZ'>Tchéquie</option>
                    <option value='TF'>Terres australes françaises</option>
                    <option value='IO'>
                      Territoire britannique de l’océan Indien
                    </option>
                    <option value='PS'>Territoires palestiniens</option>
                    <option value='TH'>Thaïlande</option>
                    <option value='TL'>Timor oriental</option>
                    <option value='TG'>Togo</option>
                    <option value='TK'>Tokelau</option>
                    <option value='TO'>Tonga</option>
                    <option value='TT'>Trinité-et-Tobago</option>
                    <option value='TN'>Tunisie</option>
                    <option value='TM'>Turkménistan</option>
                    <option value='TR'>Turquie</option>
                    <option value='TV'>Tuvalu</option>
                    <option value='UA'>Ukraine</option>
                    <option value='UY'>Uruguay</option>
                    <option value='VU'>Vanuatu</option>
                    <option value='VE'>Venezuela</option>
                    <option value='VN'>Viêt Nam</option>
                    <option value='WF'>Wallis-et-Futuna</option>
                    <option value='YE'>Yémen</option>
                    <option value='ZM'>Zambie</option>
                    <option value='ZW'>Zimbabwe</option>
                    <option value='EG'>Égypte</option>
                    <option value='AE'>Émirats arabes unis</option>
                    <option value='EC'>Équateur</option>
                    <option value='ER'>Érythrée</option>
                    <option value='VA'>État de la Cité du Vatican</option>
                    <option value='US'>États-Unis</option>
                    <option value='ET'>Éthiopie</option>
                    <option value='BV'>Île Bouvet</option>
                    <option value='CX'>Île Christmas</option>
                    <option value='NF'>Île Norfolk</option>
                    <option value='IM'>Île de Man</option>
                    <option value='KY'>Îles Caïmans</option>
                    <option value='CC'>Îles Cocos</option>
                    <option value='CK'>Îles Cook</option>
                    <option value='FO'>Îles Féroé</option>
                    <option value='HM'>Îles Heard-et-MacDonald</option>
                    <option value='FK'>Îles Malouines</option>
                    <option value='MP'>Îles Mariannes du Nord</option>
                    <option value='MH'>Îles Marshall</option>
                    <option value='PN'>Îles Pitcairn</option>
                    <option value='SB'>Îles Salomon</option>
                    <option value='TC'>Îles Turques-et-Caïques</option>
                    <option value='VG'>Îles Vierges britanniques</option>
                    <option value='VI'>Îles Vierges des États-Unis</option>
                    <option value='UM'>
                      Îles mineures éloignées des États-Unis
                    </option>
                    <option value='AX'>Îles Åland</option>
                  </select>
                </div>

                <div className='form-group'>
                  <button type='submit' className='btn'>
                    S'inscrire
                  </button>
                </div>
                <div className='col-12 text-center condition'>
                  {labels.EDMUS_CLUB_CONDITION}
                </div>
              </form>
            </div>
          </div>
          {/* <div className='columns space-bottom-20'>
            <div className='column center'>
              <Link to='/wine-angels' className='wine-angels-link'>
                {labels.CONTACT_WINE_ANGELS}
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Newsletter;
